import React, {useContext, useEffect, useState} from "react";
import {Col, Row, Typography} from 'antd';
import {AuthContext, AuthContextState} from "../auth/AuthContext";

const {Title} = Typography;


export const ThankYou = () => {
    const authContext = useContext<AuthContextState>(AuthContext);
    const [email, setEmail] = useState("loading ...");

    useEffect(() => {
        if (authContext.isAuthorized) {
            setEmail(authContext.user.email);
        }
    }, [authContext.isLoadingAuthorization, authContext.isAuthorized])

    return (<>
    <Row>
        <Col>
            <Title>Thank you for registration!</Title>
        </Col>
    </Row>
        <Row>
            <Col>
                Email: {email}
            </Col>
        </Row>
        <Row>
            <Col>
                <a href={"/sign-out"}>Sign out</a>
            </Col>
        </Row>
    </>);
}
