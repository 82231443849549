import * as React from 'react';
import {ReactElement, useContext, useEffect, useState} from 'react';
import {Form, Input, Button, Row, Col, Alert, Typography} from 'antd';

import {useNavigate} from 'react-router-dom';
import {Routes} from '../http/routeBuilder';
import {Auth as AmplifyAuth} from "aws-amplify";
import {AuthContext, AuthContextState} from "../auth/AuthContext";

export const Login = (): ReactElement => {
    const authContext = useContext<AuthContextState>(AuthContext);
    const [isFailed, setIsFailed] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const onFinish = async (values: any) => {
        setIsFailed(false);
        setLoading(true);

        try {
            const user = await AmplifyAuth.signIn(values.username, values.password);
            setLoading(false);

            if (user) {
                authContext.setUser({
                    email: user.attributes.email,
                    id: user.attributes.sub
                });

                window.location.href = '/';
            }
        } catch (e) {
            setIsFailed(true);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    let errorMessage = <></>;
    if (isFailed) {
        errorMessage = (
            <Row justify={'center'} style={{marginBottom: '30px'}}>
                <Col>
                    <Alert
                        message="Login failed. Please check your email or password."
                        type="error"
                    />
                </Col>
            </Row>
        );
    }

    return (
        <>
            <Row style={{marginTop: '10vh'}}>
                <Col>
                    &nbsp;
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col>
                    <Typography.Title>Resony</Typography.Title>
                </Col>
            </Row>
            {errorMessage}
            <Row justify={'center'}>
                <Col>
                    <Form
                        name="basic"
                        labelCol={{span: 8}}
                        disabled={isLoading}
                        wrapperCol={{span: 16}}
                        initialValues={{remember: true}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="username"
                            rules={[{required: true, message: 'Email is required'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{required: true, message: 'Password is required'}]}
                        >
                            <Input.Password/>
                        </Form.Item>

                        {/*<Form.Item*/}
                        {/*  name="remember"*/}
                        {/*  valuePropName="checked"*/}
                        {/*  wrapperCol={{ offset: 8, span: 16 }}*/}
                        {/*>*/}
                        {/*  <Checkbox>Remember me</Checkbox>*/}
                        {/*</Form.Item>*/}

                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                            <Button type="primary" htmlType="submit">
                                Login
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <a href={Routes.createAccount()}>Create account</a>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={{span: 20, offset: 2}} lg={{span: 10, offset: 6}}>
                    &copy; 2023 | <a href={"https://www.resony.health"}>resony.health</a> | Resony - Improving access to
                    effective mental health
                </Col>
            </Row>
        </>
    );
};
