import * as React from 'react';
import {Layout, Menu} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';

const {Content, Footer, Sider} = Layout;

export const MainLayout = (props: any) => {
    const navigator = useNavigate();
    // eslint-disable-next-line react/prop-types
    const {to, staticContext, children, ...rest} = props;

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Layout className="site-layout" style={{backgroundColor: '#fff'}}>
                <Content>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 360}}
                    >
                        {children}
                    </div>
                </Content>
                <Footer style={{textAlign: 'center'}}>
                    &copy; 2023 |{' '}
                    <a
                        target={'_blank'}
                        href={
                            'https://www.resony.health/'
                        }
                    >
                        www.resony.health
                    </a>
                    {' | '}
                    <a target={'_blank'} href={'mailto:support@resony.health'} rel="noreferrer">Support</a>
                </Footer>
            </Layout>
        </Layout>
    );
};
