import React, {useContext, useEffect} from 'react';
import './App.css';
import {MainLayout} from "./layout/MainLayout";
import {Route, Routes} from "react-router";
import {Home} from "./pages/Home";
import {AuthContext, AuthContextState} from "./auth/AuthContext";
import {Login} from "./pages/Login";
import {SignOut} from "./pages/SignOut";
import {FullPageSpin} from "./components/FullPageSpin";
import {Amplitude} from './analytics/Amplitude';
import {useLocation} from "react-router-dom";
import {CreateAccount} from "./pages/CreateAccount";
import {ThankYou} from "./pages/ThankYou";
import {NotFound} from "./pages/NotFound";

function App() {
    const authContext = useContext<AuthContextState>(AuthContext);

    let location = useLocation();

    useEffect(() => {
        Amplitude.getInstance().then(async (a) => {
            await a.track('page-load');
        })
    }, [location]);

    useEffect(() => {
        if (authContext.isAuthorized) {
            Amplitude.getInstance().then(async (a) => {
                a.setUser(authContext.user);
            })
        }
    }, [authContext.isAuthorized, authContext.user]);

    if (authContext.isLoadingAuthorization) {
        return (
            <FullPageSpin visible={authContext.isLoadingAuthorization}/>
        );
    }

    if (!authContext.isAuthorized) {
        return (
            <Routes>
                <Route path="/" element={<Login/>}/>
                <Route path="/thank-you" element={<MainLayout><ThankYou/></MainLayout>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/create-account/:id" element={<CreateAccount/>}/>
                <Route path="/create-account/" element={<CreateAccount/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<MainLayout><Home/></MainLayout>}/>
            <Route path="/thank-you" element={<MainLayout><ThankYou/></MainLayout>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/sign-out" element={<SignOut/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    );
}

export default App;
