import React, {useContext, useEffect, useState} from "react";
import {Col, Row, Typography} from 'antd';
import {AuthContext, AuthContextState} from "../auth/AuthContext";
import {sendPost} from "../http/requester";

const {Title} = Typography;


export const Home = () => {
    const authContext = useContext<AuthContextState>(AuthContext);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        if(authContext.isAuthorized) {
            sendPost('v1/has-premium-access', {
                email: authContext.user.email,
            }).then((r) => r.json()).then((d) => {
                setData(d);
            })
        }
    }, [authContext.isAuthorized]);


    return (<>
    <Row>
        <Col>
            <Title>Home</Title>
        </Col>
    </Row>
        <Row>
            <Col>
                Email: {authContext.user.email}
            </Col>
        </Row>
        <Row>
            <Col>
                Has premium access: {data?.HasPremiumAccess.toString() ?? "false"}
            </Col>
        </Row>
        <Row>
            <Col>
                <a href={"/sign-out"}>Sign out</a>
            </Col>
        </Row>
    </>);
}
