import * as React from 'react';
import {ReactElement, useContext, useState} from 'react';
import {Form, Input, Button, Row, Col, Alert, Typography} from 'antd';

import {useNavigate, useParams} from 'react-router-dom';
import {Auth as AmplifyAuth} from "aws-amplify";
import {AuthContext, AuthContextState} from "../auth/AuthContext";
import {sendPost} from "../http/requester";

export const CreateAccount = (): ReactElement => {
    let {id} = useParams();
    const navigator = useNavigate();
    const authContext = useContext<AuthContextState>(AuthContext);
    const [isFailed, setIsFailed] = useState(false);
    const [error, setError] = useState("");
    const [mode, setMode] = useState("create");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);

    const onCreate = async (values: any) => {
        setIsFailed(false);
        setLoading(true);
        setError("");


        let isPasswordValid = /^[\S]+.*[\S]+$/.test(values.password);
        isPasswordValid = isPasswordValid && 6 <= values.password.length;
        if (!isPasswordValid) {
            setLoading(false);
            setIsFailed(true);
            setError("Please check your password it must have at least 6 characters");
        } else {
            try {
                const user = await AmplifyAuth.signUp(values.username,
                    values.password,
                    values.username);

                if (user) {
                    setUserName(values.username);
                    setPassword(values.password);
                    setMode("validate");
                }

                setLoading(false);
            } catch (e: any) {
                console.log(e);
                console.log(e.code);

                setLoading(false);
                setIsFailed(true);

                if (e.code === "InvalidPasswordException") {
                    setError("Please check your password.")
                } else if (e.code === "UsernameExistsException") {
                    setError("Please choose different email.")
                }
            }
        }
    };

    const onValidate = async (values: any) => {
        setIsFailed(false);
        setLoading(true);
        setError("");


        const isPasswordValid = /^[\S]+.*[\S]+$/.test(values.password);
        if (!isPasswordValid) {
            setLoading(false);
            setIsFailed(true);
            setError("Please check your password it must have at least 6 characters");
        }

        try {
            const confirmResult = await AmplifyAuth.confirmSignUp(userName, values.code);
            if (confirmResult === "SUCCESS") {
                const user = await AmplifyAuth.signIn(userName, password);
                if (user) {
                    const result = await sendPost('v1/save-premium-access-by-link', {
                        email: userName,
                        deepLink: id,
                    });
                    if (user) {
                        navigator("/thank-you");
                    }
                }
            }
            setLoading(false);
            setError("Please enter valid code.")
        } catch (e: any) {
            console.log(e);
            console.log(e.code);

            setLoading(false);
            setIsFailed(true);

            setError("Please enter valid code.")
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    let errorMessage = <></>;
    if (isFailed) {
        errorMessage = (
            <Row justify={'center'} style={{marginBottom: '30px'}}>
                <Col>
                    <Alert
                        message={error}
                        type="error"
                    />
                </Col>
            </Row>
        );
    }

    return (
        <>
            <Row style={{marginTop: '10vh'}}>
                <Col>
                    &nbsp;
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col>
                    <Typography.Title>Create Account</Typography.Title>
                </Col>
            </Row>
            {errorMessage}
            {mode === "create" && (<Row justify={'center'}>
                <Col>
                    <Form
                        name="basic"
                        labelCol={{span: 8}}
                        disabled={isLoading}
                        wrapperCol={{span: 16}}
                        initialValues={{remember: true}}
                        onFinish={onCreate}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="username"
                            rules={[{required: true, message: 'Email is required'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{required: true, message: 'Password is required'}]}
                        >
                            <Input.Password/>
                        </Form.Item>

                        {/*<Form.Item*/}
                        {/*  name="remember"*/}
                        {/*  valuePropName="checked"*/}
                        {/*  wrapperCol={{ offset: 8, span: 16 }}*/}
                        {/*>*/}
                        {/*  <Checkbox>Remember me</Checkbox>*/}
                        {/*</Form.Item>*/}

                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                            <Button type="primary" htmlType="submit">
                                Create account
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>)}
            {mode === "validate" && (<Row justify={'center'}>
                <Col>
                    <Form
                        name="basic"
                        labelCol={{span: 8}}
                        disabled={isLoading}
                        wrapperCol={{span: 16}}
                        initialValues={{remember: true}}
                        onFinish={onValidate}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Code"
                            name="code"
                            rules={[{required: true, message: 'Code is required'}]}
                        >
                            <Input/>
                        </Form.Item>


                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                            <Button type="primary" htmlType="submit">
                                Validate
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>)}
            <Row justify={'center'}>
                <Col xs={{span: 20, offset: 2}} lg={{span: 10, offset: 6}}>
                    &copy; 2023 | <a href={"https://www.resony.health"}>resony.health</a> | Resony - Improving access to
                    effective mental health
                </Col>
            </Row>
        </>
    );
};
