import * as React from 'react';
import { ReactElement, useEffect } from 'react';

import { Routes } from '../http/routeBuilder';
import {Auth as AmplifyAuth} from "aws-amplify";

export const SignOut = (): ReactElement => {
  useEffect(() => {
    AmplifyAuth.signOut().then(() => {
      window.location.href = Routes.login();
    });
  }, []);

  return <></>;
};
