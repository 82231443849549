import * as React from 'react';
import {ReactElement, useContext} from 'react';
import {Row, Col, Typography} from 'antd';
import {AuthContext, AuthContextState} from "../auth/AuthContext";

export const NotFound = (): ReactElement => {
    const authContext = useContext<AuthContextState>(AuthContext);

    return (
        <>
            <Row style={{marginTop: '10vh'}}>
                <Col>
                    &nbsp;
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col>
                    <Typography.Title>Not Found</Typography.Title>
                </Col>
            </Row>
            {authContext.isAuthorized && (<Row justify={"center"}>
                <Col>
                    <a href={"/sign-out"}>Sign out</a>
                </Col>
            </Row>)}
            <Row justify={'center'}>
                <Col xs={{span: 20, offset: 2}} lg={{span: 10, offset: 6}}>
                    &copy; 2023 | <a href={"https://www.resony.health"}>resony.health</a> | Resony - Improving access to
                    effective mental health
                </Col>
            </Row>
        </>
    );
};
