import { Routes } from './routeBuilder';
import {Auth as AmplifyAuth} from "@aws-amplify/auth/lib-esm/Auth";


const getAuthHeaders = async (): Promise<any> => {
  const session = await AmplifyAuth.currentSession();
  const token = session.getIdToken().getJwtToken();

  return {
    Authorization: token,
    'Content-Type': 'application/json',
  };
};

export async function sendPost(
  url: string,
  obj: any = null,
): Promise<Response> {
  const headers = await getAuthHeaders();

  if (null == obj) {
    return await fetch(Routes.api.url(url), {
      method: 'POST',
      headers: headers,
    });
  } else {
    return await fetch(Routes.api.url(url), {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(obj),
    });
  }
}

export async function sendPut(url: string, obj: any): Promise<any> {
  const headers = await getAuthHeaders();
  const resp = await fetch(Routes.api.url(url), {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(obj),
  });

  return resp;
}

export async function sendDelete(url: string): Promise<any> {
  const headers = await getAuthHeaders();
  const resp = await fetch(Routes.api.url(url), {
    method: 'DELETE',
    headers: headers,
  });

  return resp;
}

export async function sendGet(url: string): Promise<any> {
  const headers = await getAuthHeaders();
  const resp = await fetch(Routes.api.url(url), {
    method: 'GET',
    headers: headers,
  });

  return resp;
}
