import { ReactElement, ReactNode, useEffect, useState } from 'react';
import * as React from 'react';
import {Auth as AmplifyAuth} from "aws-amplify";
import {useLocation, useNavigate} from "react-router-dom";

export type ApplicationUser = {
  email: string;
  id: string;
};

const defaultApplicationUser = {
  email: '',
  id: '',
};

export type AuthContextState = {
  isAuthorized: boolean;
  isLoadingAuthorization: boolean;
  user: ApplicationUser;
  setUser: (u: ApplicationUser) => void;
};

const defaultAuthContextState = {
  isAuthorized: false,
  isLoadingAuthorization: true,
  user: defaultApplicationUser,
  setUser: () => {},
};

export const AuthContext = React.createContext<AuthContextState>(
  defaultAuthContextState,
);

type AuthContextProps = {
  children?: ReactNode | undefined;
};

export const Auth = ({ children }: AuthContextProps): ReactElement => {
  let location = useLocation();
  const [isAuthorizedUser, setIsAuthorizedUser] = useState(false);
  const [user, setUser] = useState<ApplicationUser>(defaultApplicationUser);
  const [isLoadingAuthorization, setIsLoadingAuthorization] = useState(true);

  useEffect(() => {
    AmplifyAuth.currentUserPoolUser().then((user) => {
      if (user) {
        setIsAuthorizedUser(true);
        setUser({
          email: user.attributes.email,
          id: user.attributes.sub
        });
      }
      setIsLoadingAuthorization(false);
    }).catch((e) => {
      setIsAuthorizedUser(false);
      setIsLoadingAuthorization(false);
    });
  }, [location]);

  return (
    <AuthContext.Provider
      value={{ isAuthorized: isAuthorizedUser, isLoadingAuthorization, user, setUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};
