import { ApiRouter } from './apiRouteBuilder';

export class RouterBuilder {
  apiUrl: string;
  api: ApiRouter;

  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL ?? "";
    this.api = new ApiRouter(this.apiUrl);
  }

  home(): string {
    return '/';
  }

  createAccount(): string {
    return process.env.REACT_APP_SITE_URL + '/create-account/';
  }

  login(): string {
    return '/login';
  }

  signOut(): string {
    return '/sign-out';
  }
}

export const Routes = new RouterBuilder();
